import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "../GellerySlider/GellerySlider.scss";

import { useTranslation } from "react-i18next";

const GellerySlider = () => {
  const { t } = useTranslation();

  return (
    <div className="HomeProjects" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/patrn1.png"})` }}>
      <h1 className="GalleryProjectsTitle">{t("Video")}</h1>
      <Swiper navigation={true} modules={[Navigation]} speed={400} resistanceRatio={0} className="GalleryProjectSlider">
        {sliderData.map((item, index) => (
          <SwiperSlide className="GallerySwiperSlide" key={index}>
            <video controls className="HomeProjectVideo">
              <source src={process.env.PUBLIC_URL + item.src} type="video/mp4" />
            </video>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GellerySlider;

const sliderData = [
  {
    id: 0,
    src: "/GalleryVideos/Buddvideo1.mp4",
    name: "Lorem House",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae omnis rerum rem blanditiis amet, a odio iste placeat unde! Perspiciatis aut incidunt ab officia cum autem impedit perferendis ducimus iusto.",
  },
  {
    id: 1,
    src: "/GalleryVideos/Buddvideo2.mp4",
    name: "Lorem House",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae omnis rerum rem blanditiis amet, a odio iste placeat unde! Perspiciatis aut incidunt ab officia cum autem impedit perferendis ducimus iusto.",
  },
  {
    id: 2,
    src: "/GalleryVideos/Buddvideo3.mp4",
    name: "Lorem House",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae omnis rerum rem blanditiis amet, a odio iste placeat unde! Perspiciatis aut incidunt ab officia cum autem impedit perferendis ducimus iusto.",
  },
];
