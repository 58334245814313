import axios from "axios";

const baseUrl = "https://api.telegram.org/bot6961795595:AAFE_xkR6xxXA36u8ocsGKIfCV1zHslwK8k/";

export const sendMessage = async (message: string): Promise<void> => {
  const url: string = `${baseUrl}sendMessage?chat_id=-4210077029&text=${message}`;

  const response: Response = await fetch(url);

  // console.log("response", response);
};

export const sendFile = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("document", file);
  const url = `${baseUrl}sendDocument?chat_id=-4210077029&text=${file}`;

  await axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
