import GelleryMain from "./Gellery/GelleryMain/GelleryMain";
import GelleryPhotos from "./Gellery/GelleryPhotos/GelleryPhotos";
import GellerySlider from "./Gellery/GellerySlider/GellerySlider";

const Gellery = () => {
  return (
    <>
      <GelleryMain />
      <GelleryPhotos />
      <GellerySlider />
    </>
  );
};
export default Gellery;
