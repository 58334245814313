import "../OurProjects/OurProjects.scss";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { useEffect, useState } from "react";

const OurProjects = () => {
  const { i18n, t } = useTranslation();
  const [projects, setProjects] = useState<any>([]);

  useEffect(() => {
    const techRef = collection(db, "projects");
    getDocs(techRef)
      .then((snapshot) => {
        let counter = 0;
        let proj: any = [];
        snapshot.docs.forEach((doc) => {
          if (counter < 2 && proj.length < 2) {
            proj.push({ ...doc.data(), id: doc.id });
            counter++;
          }
        });
        setProjects(proj);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="OurProjects">
      <div className="OurProjectsTitle">{t("OurProjectsTitle")}</div>
      <div className="OurProjectsContainer">
        {projects && Object.keys(projects).length > 0 && (
          <div className="OurProjectsItem">
            <div className="OurProjectsItemImg">
              <img src={projects[0].img} alt="decor" />
            </div>
            <h2 className="OurProjectsItemTitle">
              {i18n.language === "en" ? projects[0].titleEng : projects[0].titleUkr}
            </h2>
          </div>
        )}
        {projects && Object.keys(projects).length > 0 && (
          <div className="OurProjectsItem">
            <div className="OurProjectsItemImg">
              <img src={projects[1].img} alt="decor" />
            </div>
            <h2 className="OurProjectsItemTitle">
              {i18n.language === "en" ? projects[1].titleEng : projects[1].titleUkr}
            </h2>
          </div>
        )}
      </div>
      <div className="OurProjectsButtonContainer">
        <Link to="/projects">
          <button className="OurProjectsButton" onClick={scrollToTop}>
            <p className="OurProjectsButtonText">{t("lookMore")}</p>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OurProjects;
