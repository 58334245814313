import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import BurgerIcon from "../../icons/BurgerIcon";
import CrossIcon from "../../icons/CrossIcon";
import LanguageSelector from "../LanguageSelector";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 1200;
  const { t, i18n } = useTranslation();

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showModal = () => {
    setModal(!modal);
    const modalEl = document.querySelector(".Modal") as HTMLElement;
    if (modalEl && modal === false) {
      modalEl.classList.add("show");
    }
    if (modalEl && modal === true) {
      modalEl.classList.remove("show");
    }
  };

  return (
    <>
      <div className="Header">
        <Link to="/">
          <div className="HeaderLogo">
            <img src={process.env.PUBLIC_URL + "/BuddesignLogo.png"} alt="" />
            <h1 className="HeaderTitle">Buddesign</h1>
          </div>
        </Link>
        {isMobile ? (
          <></>
        ) : (
          <div className="HeaderRoutes">
            <div className="Dropdown">
              {t("headerNav1")}
              <div className="HeaderDropdownServices">
                <Link to="/technique" className="HeaderServicesRouteItem">
                  {t("headerNav2")}
                </Link>
                <Link to="/works" className="HeaderServicesRouteItem">
                  {t("headerNav3")}
                </Link>
                <Link to="/products" className="HeaderServicesRouteItem">
                  {t("headerNav4")}
                </Link>
                <Link to="/outsourcing" className="HeaderServicesRouteItem">
                  {t("headerNav5")}
                </Link>
                <Link to="/houses" className="HeaderServicesRouteItem">
                  {t("headerNav6")}
                </Link>
                <Link to="/recyclables" className="HeaderServicesRouteItem">
                  {t("headerNav7")}
                </Link>
              </div>
            </div>

            <Link to="/about" className="HeaderRouteItem">
              {t("headerNav8")}
            </Link>
            <Link to="/projects" className="HeaderRouteItem">
              {t("headerNav9")}
            </Link>
            <Link to="/gallery" className="HeaderRouteItem">
              {t("headerNav10")}
            </Link>
            <Link to="#" onClick={scrollToBottom} className="HeaderRouteItem">
              {t("headerNav11")}
            </Link>
            {/* <div className="HeaderLanguage">
              <div className="LangDropdown">
                <ArrowDown />
                <span>UA</span>
                <div className="HeaderDropdownServices">
                  <button className="HeaderRouteItem">UA</button>
                  <button className="HeaderRouteItem">ENG</button>
                </div>
              </div>
            </div> */}
            <LanguageSelector i18n={i18n}></LanguageSelector>
          </div>
        )}
        {isMobile ? (
          <div className="headerMobile">
            <LanguageSelector i18n={i18n}></LanguageSelector>
            <button className="MenuButton" onClick={showModal}>
              <BurgerIcon></BurgerIcon>
            </button>
            <div className="Modal">
              <div className="ModalHead">
                <button className="CloseButton" onClick={showModal}>
                  <CrossIcon></CrossIcon>
                </button>
              </div>
              <div className="ModalBody">
                <Link
                  to="/technique"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav2")}
                </Link>
                <Link
                  to="/works"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav3")}
                </Link>
                <Link
                  to="/products"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav4")}
                </Link>
                <Link
                  to="/outsourcing"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav5")}
                </Link>
                <Link
                  to="/houses"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav6")}
                </Link>
                <Link
                  to="/recyclables"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav7")}
                </Link>

                <Link
                  to="/about"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav8")}
                </Link>
                <Link
                  to="/projects"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav9")}
                </Link>
                <Link
                  to="/gallery"
                  className="modalNavItem"
                  onClick={() => {
                    scrollToTop();
                    showModal();
                  }}
                >
                  {t("headerNav10")}
                </Link>
                <Link to="#" onClick={scrollToBottom} className="modalNavItem">
                  {t("headerNav11")}
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Header;
