import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import "./HomeServices.scss";

const HomeServices = () => {
  const { t, i18n } = useTranslation();
  const [allTechnic, setAllTechnic] = useState<any>([]);
  const [technicIter, setTechnicIter] = useState<any>(0);

  const [allWork, setAllWork] = useState<any>([]);
  const [workIter, setWorkIter] = useState<any>(0);

  const changeTechnic = (index: number) => {
    setTechnicIter(index);
  };

  const changeWork = (index: number) => {
    setWorkIter(index);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    const techRef = collection(db, "technic_types");
    const workRef = collection(db, "works");
    getDocs(techRef)
      .then((snapshot) => {
        let counter = 0;
        let tech: any = [];
        snapshot.docs.forEach((doc) => {
          if (counter < 3 && tech.length < 3) {
            tech.push({ ...doc.data(), id: doc.id });
            counter++;
          }
        });
        setAllTechnic(tech);
      })
      .catch((err) => {
        console.log(err.message);
      });

    getDocs(workRef)
      .then((snapshot) => {
        let counter = 0;
        let work: any = [];
        snapshot.docs.forEach((doc) => {
          if (counter < 3 && work.length < 3) {
            work.push({ ...doc.data(), id: doc.id });
            counter++;
          }
        });
        setAllWork(work);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="HomeServices" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/patrn1.png"})` }}>
      <div className="AboutTechnique">
        {allTechnic && Object.keys(allTechnic).length > 0 && (
          <div style={{ backgroundImage: `url(${allTechnic[technicIter].img})` }} className="AboutTechniqueImg">
            <div className="AboutTechniqueImgText">
              <h1 className="AboutTechniqueImgTitle">
                {i18n.language === "en" ? allTechnic[technicIter].titleEng : allTechnic[technicIter].titleUkr}
              </h1>
              <h4 className="AboutTechniqueImgDescription">{t("Technique")}</h4>
            </div>
          </div>
        )}
        <div className="AboutTechniqueText">
          <h1 className="AboutTechniqueTextTitle">{t("Technique")}</h1>
          <p className="AboutTechniqueTextDescription">{t("aboutTechniqueTextDescription")}</p>
          <div className="AboutTechniqueButtons">
            <button
              className="AboutTechniqueButtonOne"
              onClick={() => {
                changeTechnic(0);
              }}
            >
              {t("aboutTechniqueButtonOne")} 1
            </button>
            <button
              className="AboutTechniqueButtonOne"
              onClick={() => {
                changeTechnic(1);
              }}
            >
              {t("aboutTechniqueButtonOne")} 2
            </button>
            <button
              className="AboutTechniqueButtonOne"
              onClick={() => {
                changeTechnic(2);
              }}
            >
              {t("aboutTechniqueButtonOne")} 3
            </button>
          </div>
          <Link to="/technique">
            <button className="AboutTechniqueButtonMore" onClick={scrollToTop}>
              {t("lookMore")}
            </button>
          </Link>
        </div>
      </div>
      <div className="AboutWork">
        <div className="AboutTechniqueText">
          <h1 className="AboutTechniqueTextTitle">{t("Works")}</h1>
          <p className="AboutTechniqueTextDescription">{t("aboutWorksTextDescription")}</p>
          <div className="AboutTechniqueButtons">
            <button
              className="AboutTechniqueButtonOne"
              onClick={() => {
                changeWork(0);
              }}
            >
              {t("aboutTechniqueButtonOne")} 1
            </button>
            <button
              className="AboutTechniqueButtonOne"
              onClick={() => {
                changeWork(1);
              }}
            >
              {t("aboutTechniqueButtonOne")} 2
            </button>
            <button
              className="AboutTechniqueButtonOne"
              onClick={() => {
                changeWork(2);
              }}
            >
              {t("aboutTechniqueButtonOne")} 3
            </button>
          </div>
          <Link to="/works">
            <button className="AboutTechniqueButtonMore" onClick={scrollToTop}>
              {t("lookMore")}
            </button>
          </Link>
        </div>
        {allWork && Object.keys(allWork).length > 0 && (
          <div style={{ backgroundImage: `url(${allWork[workIter].img})` }} className="AboutTechniqueImg">
            <div className="AboutTechniqueImgText">
              <h1 className="AboutTechniqueImgTitle">
                {i18n.language === "en" ? allWork[workIter].titleEng : allWork[workIter].titleUkr}
              </h1>
              <h4 className="AboutTechniqueImgDescription">{t("Works")}</h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeServices;
