import "swiper/css";
import "./HomeProjects.scss";
import ArrowRight from "../../../icons/ArrowRight";
import ArrowLeft from "../../../icons/ArrowLeft";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

const HomeProjects = () => {
  const { i18n, t } = useTranslation();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [projects, setProjects] = useState<any>([]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(() => {
    const techRef = collection(db, "projects");
    getDocs(techRef)
      .then((snapshot) => {
        let counter = 0;
        let proj: any = [];
        snapshot.docs.forEach((doc) => {
          if (counter < 3 && proj.length < 3) {
            proj.push({ ...doc.data(), id: doc.id });
            counter++;
          }
        });
        setProjects(proj);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="HomeProjects" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/patrn1.png"})` }}>
      <h1 className="HomeProjectsTitle">{t("HomeProjectsTitle")}</h1>
      <Swiper
        modules={[Navigation]}
        speed={400}
        navigation={{
          nextEl: navigationNextRef.current,
          prevEl: navigationPrevRef.current,
        }}
        onBeforeInit={(swiper: any) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        resistanceRatio={0}
        mousewheel={{
          enabled: true,
          forceToAxis: true,
        }}
        className="HomeProjectSlider"
      >
        {projects.map((item: any, index: any) => (
          <SwiperSlide key={index}>
            <div style={{ backgroundImage: `url(${item.img})` }} className="HomeProjectSlide"></div>
            <div className="HomeProjectSlideData">
              <h1 className="HomeProjectSmallTitle">{t("HomeProjectSmallTitle")}</h1>
              <h1 className="HomeProjectTitle">{i18n.language === "en" ? item.titleEng : item.titleUkr}</h1>
              <p className="HomeProjectDescription">
                {i18n.language === "en" ? item.descriptionEng : item.descriptionUkr}
              </p>
              <Link to="/projects">
                <button className="HomeProjectButton" onClick={scrollToTop}>
                  {t("lookMore")}
                </button>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="HomeProjectsNavigation">
        <button ref={navigationPrevRef} className="HomeProjectNavButton">
          <ArrowLeft></ArrowLeft>
        </button>
        <button ref={navigationNextRef} className="HomeProjectNavButton">
          <ArrowRight></ArrowRight>
        </button>
      </div>
    </div>
  );
};

export default HomeProjects;
