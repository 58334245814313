import "../RecyclablesCards/RecyclablesCards.scss";
import "../../../styles/Modals.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CrossIcon from "../../../icons/CrossIcon";

const RecyclablesCards = ({ recyclables }: any) => {
  const { i18n } = useTranslation();
  const [isModal, setIsModal] = useState(false);
  const [currentImage, setCurrentImage] = useState<string>("");
  const [showBtn, setShowBtn] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1600) {
      setShowBtn(false);
    } else if (width < 800) {
      setShowBtn(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = (image: string) => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 800) {
      setIsModal(true);
      setCurrentImage(image);
    }
  };
  const openModalMobile = (image: string) => {
    setIsModal(true);
    setCurrentImage(image);
  };

  return (
    <div className="RecyclablesCards">
      {isModal ? (
        <div className="ImageModal">
          <div className="ImageModalOverlay" onClick={() => setIsModal(false)}></div>
          <div className="ImageModalBody">
            <div className="ImageHeader">
              <button className="ImageHeaderClose" onClick={() => setIsModal(false)}>
                <CrossIcon />
              </button>
            </div>
            <img src={currentImage} alt="" className="ModalImage" />
          </div>
        </div>
      ) : null}
      <div className="RecyclablesCardsBody">
        {recyclables.map((item: any) => (
          <div className="RecyclablesImg" key={item.id} onClick={() => openModal(item.img)}>
            <div className="RecyclablesImgBackground" style={{ backgroundImage: `url(${item.img})` }} />
            <div className="RecyclablesImgText">
              <h2 className="RecyclablesImgTitle"> {i18n.language === "en" ? item.titleEng : item.titleUkr}</h2>
              {showBtn ? (
                <button
                  className="ImageModalItemBtn"
                  onClick={() => {
                    openModalMobile(item.img);
                  }}
                >
                  {i18n.language === "en" ? "View" : "Дивитися"}
                </button>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecyclablesCards;
