import { Link } from "react-router-dom";
import "./HomeAbout.scss";
import { useTranslation } from "react-i18next";

const HomeAbout = () => {
  const { t, i18n } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className="MainAbout">
      <div className="MainAboutContainer">
        <div className="MainImage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/Years.webp"})` }}>
          <div className="MainImageBlock">
            <h2
              className="MainImageNumber"
              style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/Years.webp"})` }}
            >
              30
            </h2>
            <p className="MainImageText">{t("mainImageText")}</p>
          </div>
        </div>
        <h1 className="AboutCompany">{t("aboutCompany")}</h1>
        {i18n.language === "uk" ? (
          <p className="AboutCompanyDescription">
            Buddesign-компанія з багаторічним досвідом, якісно виконаними проєктами, командою професіоналів та сучасною
            технікою.
          </p>
        ) : (
          <p className="AboutCompanyDescription">
            Buddesign is a company with many years of experience, high-quality projects, a team of professionals, and
            modern equipment.
          </p>
        )}
        <Link to="/about" onClick={scrollToTop}>
          <button className="MainAboutButton">{t("mainAboutButton")}</button>
        </Link>
      </div>
      <div className="Photo">
        <img src={process.env.PUBLIC_URL + "/ForHome/HomeAboutImg.webp"} alt="" className="HomePhotoAbout" />
      </div>
    </div>
  );
};

export default HomeAbout;
