import React, { useEffect, useState } from "react";
import "../ProjectsSlider/ProjectsSlider.scss";
import "swiper/css";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

const ProjectsSlider: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [projects, setProjects] = useState<any>([]);

  useEffect(() => {
    const techRef = collection(db, "projects");
    getDocs(techRef)
      .then((snapshot) => {
        let counter = 0;
        let proj: any = [];
        snapshot.docs.forEach((doc) => {
          if (counter < 3 && proj.length < 3) {
            proj.push({ ...doc.data(), id: doc.id });
            counter++;
          }
        });
        setProjects(proj);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="ProjectsSlider">
      <div className="ProjectsSliderText">
        <h1 className="ProjectsSliderTitle">{t("ProjectsOurContentTitle2")}</h1>
        <p className="ProjectsSliderDescription">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.
        </p>
      </div>
      <div className="ProjectsSliderBody">
        <div className="ProjectsSliderBackground"></div>
        <div className="ProjectsSliderBackgroundSmall"></div>
        <Swiper
          pagination={{
            clickable: true,
          }}
          speed={400}
          grabCursor={true}
          modules={[Pagination]}
          resistanceRatio={0}
          className="ProjectsSliderSwiper"
        >
          {projects.map((item: any, index: any) => (
            <SwiperSlide className="ProjectsSliderSlide">
              <img src={item.img} alt="" className="ProjectsSliderImg" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ProjectsSlider;
