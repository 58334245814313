import React, { useEffect, useState } from "react";
import "../ProductsCards/ProductsCards.scss";
import "../../../styles/Modals.scss";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../../icons/CrossIcon";

const ProductsCards = ({ products }: any) => {
  const { i18n } = useTranslation();
  const [isModal, setIsModal] = useState(false);
  const [currentImage, setCurrentImage] = useState<string>("");
  const [showBtn, setShowBtn] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 1600) {
      setShowBtn(false);
    } else if (width < 800) {
      setShowBtn(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openModal = (image: string) => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 800) {
      setIsModal(true);
      setCurrentImage(image);
    }
  };
  const openModalMobile = (image: string) => {
    setIsModal(true);
    setCurrentImage(image);
  };
  return (
    <div className="ProductsCards">
      {" "}
      {isModal ? (
        <div className="ImageModal">
          <div className="ImageModalOverlay" onClick={() => setIsModal(false)}></div>
          <div className="ImageModalBody">
            <div className="ImageHeader">
              <button className="ImageHeaderClose" onClick={() => setIsModal(false)}>
                <CrossIcon />
              </button>
            </div>
            <img src={currentImage} alt="" className="ModalImage" />
          </div>
        </div>
      ) : null}
      <div className="ProductsCardsBody">
        {products.map((product: any) => (
          <div className="ProductsImg" key={product.id} onClick={() => openModal(product.img)}>
            <div className="ProductsImgBackground" style={{ backgroundImage: `url(${product.img})` }} />
            <div className="ProductsImgText">
              <h2 className="ProductsImgTitle">{i18n.language === "en" ? product.titleEng : product.titleUkr}</h2>
              <h4 className="ProductsImgPrice">
                {i18n.language === "en" ? "uah" : "грн"} {product.price}{" "}
                <span>|{i18n.language === "en" ? "t." : "т."}</span>
                {showBtn ? (
                  <button
                    className="ImageModalItemBtn"
                    onClick={() => {
                      openModalMobile(product.img);
                    }}
                  >
                    {i18n.language === "en" ? "View" : "Дивитися"}
                  </button>
                ) : null}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsCards;
