import "../ProjectsOur/ProjectsOur.scss";

import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import { useEffect, useState } from "react";

const ProjectsOur = () => {
  const { i18n, t } = useTranslation();
  const [projects, setProjects] = useState<any>([]);

  useEffect(() => {
    const techRef = collection(db, "projects");
    getDocs(techRef)
      .then((snapshot) => {
        let counter = 0;
        let proj: any = [];
        snapshot.docs.forEach((doc) => {
          if (counter < 2 && proj.length < 2) {
            proj.push({ ...doc.data(), id: doc.id });
            counter++;
          }
        });
        setProjects(proj);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="ProjectsOur">
      <h2 className="ProjectsOurTitle">{t("ProjectsOurTitle")}</h2>
      <div className="ProjectsOurContent1">
        {projects && Object.keys(projects).length > 0 && (
          <>
            <div className="ProjectsOurContentText">
              <h2 className="ProjectsOurContentTitle">{t("ProjectsOurContentTitle1")}</h2>
              <p className="ProjectsOurText">
                {i18n.language === "en" ? projects[0].descriptionEng : projects[0].descriptionUkr}
              </p>
            </div>
            <div className="ProjectsOurContentImg">
              <img src={`${projects[0].img}`} alt="decor" />
            </div>
          </>
        )}
      </div>
      <div className="ProjectsOurContent2">
        {projects && Object.keys(projects).length > 0 && (
          <>
            <div className="ProjectsOurContentImg">
              <img src={`${projects[1].img}`} alt="decor" />
            </div>
            <div className="ProjectsOurContentText">
              <h2 className="ProjectsOurContentTitle">{t("ProjectsOurContentTitle2")}</h2>
              <p className="ProjectsOurText">
                {i18n.language === "en" ? projects[1].descriptionEng : projects[1].descriptionUkr}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectsOur;
