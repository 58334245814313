export const getFormSubmissionCount = (): number => {
  const count = localStorage.getItem("formSubmissionCount");
  return count ? parseInt(count, 10) : 0;
};

export const incrementFormSubmissionCount = (): void => {
  const count = getFormSubmissionCount();
  localStorage.setItem("formSubmissionCount", (count + 1).toString());
};

export const getFormVacSubmissionCount = (): number => {
  const count = localStorage.getItem("formVacSubmissionCount");
  return count ? parseInt(count, 10) : 0;
};

export const incrementFormVacSubmissionCount = (): void => {
  const count = getFormSubmissionCount();
  localStorage.setItem("formVacSubmissionCount", (count + 1).toString());
};

// export const resetFormSubmissionCount = () => {
//     localStorage.setItem('formSubmissionCount', '0');
// };
