import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCiuf0UVpe9bPLWegA4cFkarYbT87Y21VI",
  authDomain: "buddesign-b5ca2.firebaseapp.com",
  projectId: "buddesign-b5ca2",
  storageBucket: "buddesign-b5ca2.appspot.com",
  messagingSenderId: "1025686895834",
  appId: "1:1025686895834:web:f2b481dc37ccb38b6939e3",
});

const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, storage };
